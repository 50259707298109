<template>
  <div class="global-box">
    <el-form :inline="true" :model="form" class="global-form global-form-inline">
      <el-form-item label="区域">
        <el-select v-model="form.houseid" placeholder="">
          <el-option label="全部" value=""></el-option>
          <el-option label="一区" value=""></el-option>
          <el-option label="二区" value=""></el-option>
         
        </el-select>
      </el-form-item>

      <el-form-item  label="识别码">
        <el-input v-model="form.employee" placeholder="" />
      </el-form-item>

      <el-form-item>
        <span class="btn-reset" @click="onReset" style="margin-right: 10px"
          >重置</span
        >
        <span class="btn-search" @click="onSubmit">查询</span>
      </el-form-item>

      <div class="env">
        <div class="title">环控器</div>
      </div>

  
    </el-form>

    shuicao
  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{}
    }
  },
  methods: {

  }
}
</script>
<style scoped lang="less" src="./style.less"></style>